
export default {
  name: 'Index',
  components: {},
  data() {
    return {
      //
    }
  }
}
